import "./Home.css";
import "../App.css";

import Footer from "../footer/Footer";
import Header from "../header/Header";
import { SWLink } from "../modules/SWLink";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import banners from "../docs/banners.pdf";
import letter from "../docs/letter.pdf";

function Home() {
	const location = useLocation();
	useEffect(() => {
		if (window.beholdWidgets !== undefined) {
			window.beholdWidgets.initialize();
		}
	}, [location]);
	return (
		<div className="App">
			<Header active={"Home"} />
			<div className="content">
				<div className="card hero">
					<div className="titleArea">
						<h3>Welcome Back!</h3>
					</div>
					<div className="bottomText">
						<div>
							<p>
								<h4>Time for year ✌️</h4>
							</p>
							{/* <p>
								Our <SWLink to="/teams" content="roster" /> has been posted and
								our <SWLink to="/schedule" content="schedule" /> is set! We're
								ready for a great year!
							</p> */}
							<p>
								It's almost time for our second ever BEACH season! Read the
								posted information below to get involved!
							</p>
						</div>
					</div>
				</div>

				<div className="card hero2">
					<div className="titleArea">
						<h3>Open Gyms</h3>
					</div>
					<div className="bottomText">
						<div>
							<p>
								<h4>Come play before tryouts!</h4>
							</p>
							<p>
								Join us for "open court" at the Tucson Racquet Club every Monday
								and Thursday 4-6pm until tryouts on February 10th!
							</p>
						</div>
					</div>
				</div>

				<div className="card hero3">
					<div className="titleArea" style={{ aspectRatio: "9/3" }}>
						<h3>Support the Falcons!</h3>
					</div>
					<div className="bottomText">
						<div>
							<p>
								<strong>
									We're gearing up for our 2nd season and we need your support!
									Your contribution will help us cover essential expenses of
									equipment, facility rentals, tournament fees, travel costs,
									team gear, and more - ensuring our team has the best possible
									experience.
								</strong>
							</p>
							<ol>
								<li>
									<a href={banners} target="_blank">
										Advertise with us! Buy a banner for the beach
									</a>
								</li>
								<li>
									<a href={letter} target="_blank">
										Click here for info on direct donations
									</a>
								</li>
							</ol>
							<p>
								Zelle donations can be sent to{" "}
								<strong>lfbvbc2024@gmail.com</strong>
							</p>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Home;
